import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  getCountFromServer,
  updateDoc,
  getDoc,
  where
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { setCallsData } from "../Redux/calls/action";
import { setLocationsData } from "../Redux/locations/action";
import { setAccountProfiles } from "../Redux/profile/action";
import { setErrorStatus } from "../Redux/status/action";
import { database } from "./firebase";
import { isValidObject } from "./validators";

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "employees"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let profiles = {};
          dataSnapshot.forEach((doc) => {
            profiles = {
              ...doc.data()
            };
          });
          setAccountProfiles(profiles);
        },
        (error) => {
          console.error(error, "from profile");
          setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      profileListener.listener === null
    ) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber)
      });
    } else if (
      props.isAuth === false &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber, props.uid]);
}

export function useLocationListener(props) {
  const [locationListener, setLocationListener] = useState({
    listener: null
  });

  const subscribeToProfile = () => {
    const profileQuery = query(
      collection(database, "locations"),
      where("access", "==", "public")
    );

    return onSnapshot(
      profileQuery,
      (dataSnapshot) => {
        let locations = {};
        dataSnapshot.forEach((doc) => {
          locations[doc.id] = {
            ...doc.data(),
            documentId: doc.id
          };
        });
        setLocationsData(locations);
      },
      (error) => {
        console.error(error, "from locations");
        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      typeof props.uid === "string" &&
      locationListener.listener === null
    ) {
      setLocationListener({
        listener: subscribeToProfile()
      });
    } else if (
      props.isAuth === false &&
      typeof locationListener.listener === "function"
    ) {
      locationListener.listener();
      setLocationListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.uid]);
}

export function useCallsListener(props) {
  const [callsListener, setCallsListener] = useState({
    listener: null
  });

  const subscribeToComplaints = (phoneNumber) => {
    const callsQuery = query(
      collection(database, "calls"),
      where("employee.phoneNumber", "==", phoneNumber),
      where("status", "in", ["Answered", "completed"]),
      where("createdAt", ">", +new Date() - 86400000),
      orderBy("createdAt", "desc")
    );

    return onSnapshot(
      callsQuery,
      (dataSnapshot) => {
        let calls = {};
        dataSnapshot.forEach((doc) => {
          calls[doc.id] = {
            ...doc.data(),
            documentId: doc.id
          };
        });
        console.log(calls, "calls data");
        setCallsData(calls);
      },
      (error) => {
        console.error(error, "from calls");
        setErrorStatus(error);
      }
    );
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      callsListener.listener === null
    ) {
      setCallsListener({
        listener: subscribeToComplaints(props.phoneNumber)
      });
    } else if (
      props.isAuth === false &&
      typeof callsListener.listener === "function"
    ) {
      callsListener.listener();
      setCallsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber, props.uid]);
}

export async function updateCompliant(compliantId, employeeId, proof) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    status: { currentStatus: "UNDER REVIEW", updatedAt: +new Date() },
    closure: {
      resolvedAt: +new Date(),
      resolvedBy: employeeId,
      proof: proof
    }
  });
}

export async function updateLinkCompliant(compliantId, data) {
  const rootRef = doc(database, "complaints", compliantId);
  await updateDoc(rootRef, {
    "issuedBy.linkedWith": data,
    "status.updatedAt": +new Date()
  });
}

export async function updateAccessSyncStatus(employeeId, status) {
  const rootRef = doc(database, "employees", employeeId);

  await updateDoc(rootRef, {
    accessSyncStatus: status
  });
}

export async function getComplaint(complaintId) {
  const docRef = doc(database, "complaints", complaintId);
  const docSnapshot = await getDoc(docRef);
  const complaintData = docSnapshot.data();
  if (isValidObject(complaintData)) {
    return { ...complaintData, documentId: docSnapshot.id };
  } else {
    return null;
  }
}

export async function getComplaintsTotalCount(props) {
  let complaintsRef = query(
    collection(database, "complaints"),
    where("status.currentStatus", "==", "OPEN")
  );

  if (props.filters?.length > 0) {
    props.filters?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        complaintsRef = query(
          complaintsRef,
          where(props.filters[index - 2], props.filters[index - 1], value)
        );
      }
    });
  }

  const count = await getCountFromServer(complaintsRef);
  return count.data().count;
}
